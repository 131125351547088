export const Data = [
    {
        question: 'How do Workmate works?',
        answer: "Join and talk to expert with the kind of help you need. It's as easy as that."
    },
    
    {
        question: 'How soon do I get response?',
        answer: " We are 24 hours, 7 days a week operational"
    },
    {
        question: 'How original are the research and writing services?',
        answer: "Our works are done and vet by experts who happens to be your friends. We conduct plagiarism check. "
    },
    {
        question: 'Can you guide me on my research work?',
        answer: "Yes, We can guide you on your research to make it the best it can be"
    },
    {
        question: 'Can I get programming related help?',
        answer: "We have team of Programmers who would help you with any kind of software problem you might have"
    },

]